import { loading, FLOW_PAGES_ENUM } from '../common'
import axios from '../axios-interceptor'
import store from "@/store";

const state = {
  proposalId: '',
  proposals: null,
}

const getters = {
  proposalId: state => state.proposalId,
  proposals: state => state.proposals,
}

const actions = {
  async getProposalsList({ commit, dispatch }) {
    try {
      const data = await axios.get('/api/available-proposals-list')

      if (data) {
        const parsedData = data['next-step-details']
        commit('SET_PROPOSALS', parsedData)
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },

  async selectProposal({ dispatch, commit }, proposalId) {
    try {
      const formDataProposal = new FormData()

      formDataProposal.append('proposal', proposalId)

      commit('SET_PROPOSAL_ID', proposalId)

      const response = await axios.post('/api/proposal-choice', formDataProposal)
      let unico = response['next-step-details']['ab-liveness-test'] === true
      localStorage.setItem('confirmDate', response['next-step-details']['12027-law'] === true ? 'S' : 'N')
      localStorage.setItem('unico', unico ? 'S' : 'N')
      setTimeout(() => {}, 500)
      dispatch('flux/nextStep', FLOW_PAGES_ENUM.PROPOSAL, { root: true });
      if(unico) {
        store.dispatch('unico/PREPARE')

      }
      localStorage.setItem('PROPOSAL_ID', proposalId)
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },
}

const mutations = {
  SET_PROPOSAL_ID(state, proposalId) {
    state.proposalId = proposalId
  },
  SET_PROPOSALS(state, proposals) {
    state.proposals = proposals
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
