const state = {
  error: {},
  success: {},
  alert: {},
}

const getters = {
  error: (state) => state.error,
  success: (state) => state.success,
  alert: (state) => state.alert,
}

const actions = {
  sendError({ commit }, message) {
    const alert = {
      id: new Date().getTime(),
      message: message,
    }
    commit('SET_ERROR', alert)
  },

  sendSuccess({ commit }, message) {
    const alert = {
      id: new Date().getTime(),
      message: message,
    }
    commit('SET_SUCCESS', alert)
  },

  sendAlert({ commit }, message) {
    const alert = {
      id: new Date().getTime(),
      message: message,
    }
    commit('SET_ALERT', alert)
  },
}

const mutations = {
  SET_ERROR(state, error) {
    state.error = error
  },

  SET_SUCCESS(state, success) {
    state.success = success
  },

  SET_ALERT(state, alert) {
    state.alert = alert
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
