const state = {
  geolocation: false,
}

const getters = {
  geolocation: (state) => state.geolocation,
}

const actions = {
  setGeolocation({ commit }, geolocation) {
    commit('SET_GEOLOCATION', geolocation)
  },
}

const mutations = {
  SET_GEOLOCATION(state, geolocation) {
    state.geolocation = geolocation
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
