import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fas, far, fab, fat)

dom.watch()

export default FontAwesomeIcon
