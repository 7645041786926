import { loading } from '../common'
import axios from '../axios-interceptor'

const state = {
  signStatus: [],
  finished: undefined,
  signStatusInterval: undefined,
}

const getters = {
  signStatus: (state) => state.signStatus,
  finished: (state) => state.finished,
}

const actions = {
  setSignStatus({ commit }, signStatus) {
    commit('SET_SIGN_STATUS', signStatus)
  },

  async retrieveSignStatus({ commit, dispatch }) {
    try {
      const getData = async () => {
        const data = await axios.get('/api/sign-status')

        if (data) {
          const parsedData = data["next-step-details"];
          commit('SET_FINISHED', parsedData)
          const finalData = Array.from(parsedData.documents).sort(
              (a, b) => a.order - b.order
          );
          console.log(finalData)
          commit("SET_SIGN_STATUS", finalData);
        }
      };

      getData()
    } catch (error) {
      loading(dispatch, false)
    }
  },

  async stopRetrieveSignStatus({ dispatch }) {
    try {
      //do nothings
    } catch (error) {
      loading(dispatch, false)
    }
  },

  async finishProcess({ dispatch }) {
    try {
      const getData = async () => {
        const formData = new FormData()
        await axios.post('/api/finish-upload', formData)
      }

      getData()
    } catch (error) {
      loading(dispatch, false)
    }
  },
}

const mutations = {
  SET_SIGN_STATUS(state, signStatus) {
    state.signStatus = signStatus
  },
  SET_FINISHED(state, finished) {
    state.finished = finished
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
