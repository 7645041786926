import store from '../index'

const state = {
  unicoLib: {
    builder: null,
    theme: null,
    camera: null,
    services: '/unico/credentials.json',
    models: '/unico/models',
    resources: '/unico/resources',
  },
  tentativas: 0,
}

const getters = {
  sdk: (state) => state.unicoLib,
}

const actions = {
  async setUnicoSDK({ commit }, sdk) {
    commit('SET_UNICO_SDK', sdk)
    console.log('salvo estado', sdk)
  },
  async PREPARE({ commit }) {
    commit('PREPARE_UNICO')
    console.log('prepare')
  },
  OPEN({ commit }) {
    commit('OPEN_UNICO')
    console.log('open')
  },
}


const mutations = {
  async OPEN_UNICO(state) {
    await state.unicoLib.open({
      on: {
        success: (data) => {
          data['unico'] = true
          store.dispatch('selfie/uploadSelfie', data)
        },
        error: (err) => {
          console.log(err)
          state.tentativas++
          if (state.tentativas <= 3) {
            console.log('iniciando retentativa:', state.tentativas)
            setTimeout(() => store.dispatch('unico/OPEN'), 1000)
          } else {
            state.tentativas = 0
          }
        },
        support: (msg) => console.log(msg),
      },
    })
  },
  SET_UNICO_SDK(state, sdk) {
    state.unicoLib = sdk
  },

  async PREPARE_UNICO(state) {
    const impo = () => import('unico-webframe')
    const { UnicoCheckBuilder, UnicoThemeBuilder, SelfieCameraTypes, UnicoConfig } = await impo()

    try {
      store.dispatch(
        'loading/setLoadingWithMessage',
        'Aguarde, preparando ambiente...',
      )
      new Promise((resolve) => {
        state.unicoLib.builder = new UnicoCheckBuilder()
        state.unicoLib.theme = new UnicoThemeBuilder()
          .setColorSilhouetteSuccess('#D50000')
          .setColorSilhouetteError('#D50000')
          .setColorSilhouetteNeutral('#fcfcfc')
          .setBackgroundColor('#dff1f5')
          .setColorText('#D50000')
          .setBackgroundColorComponents('#D50000')
          .setColorTextComponents('#dff1f5')
          .setBackgroundColorButtons('#D50000')
          .setColorTextButtons('#dff1f5')
          .setBackgroundColorBoxMessage('#fff')
          .setColorTextBoxMessage('#000')
          .setHtmlPopupLoading(
            '<div style="position:absolute;top:45%;right:50%;transform:translate(50%,-50%);z-index:10;text-align:center;">Carregando...</div>',
          )
          .build()
        resolve(state.unicoLib)
      })
        .then(async () => {
          state.unicoLib.services = new UnicoConfig()
            .setProjectNumber("455713794334820469857580")
            .setProjectId("smartilve_desacoplado")
            .setMobileSdkAppId("3:1084797:js")
            .setHostname("https://homolog-bradesco.nexyon.com.br")
            .setHostInfo("nRMqSJJeWMZ0K4n9Dxs/Zhb5RslAxes+pmH0gJgmVtbY5HPfrxwsWWOj0ip3SuSy")
            .setHostKey("VHZNPsJHO/NDeEdDdkub/4ui73pTL9ReFK0qX2fKN8sB0zqnSfWgWyt9AYpFDKgW");
          await state.unicoLib.builder
            .setTheme(state.unicoLib.theme)
            .setModelsPath(state.unicoLib.models)
            .setResourceDirectory(state.unicoLib.resources)
          state.unicoLib.camera = await state.unicoLib.builder.build()
          await state.unicoLib.camera
            .prepareSelfieCamera(state.unicoLib.services, SelfieCameraTypes['SMART'])
            .then((opener) => {
              state.unicoLib = opener
              // this.$store.dispatch('unico/setUnicoSDK', opener).then(() =>
              //     this.$store.dispatch('loading/setLoading', false, {root: true})
              // )
              console.log('armazenado')
              store.dispatch('loading/setLoading', false, { root: true })
            })
            .catch((err) => console.log(`Erro ao preparar camera ${err}`))
        })

    } catch (err) {
      console.log(err)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
