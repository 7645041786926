import {loading} from '../common'
import axios from '../axios-interceptor'

const state = {
    uploadStatus: [],
}

const getters = {
    uploadStatus: (state) => state.uploadStatus,
}

const actions = {
    setUploadStatus({commit}, uploadStatus) {
        commit('SET_UPLOAD_STATUS', uploadStatus)
    },

    async retrieveUploadStatus({commit, dispatch}) {
        try {
            const getData = async () => {
                const data = await axios.get('/api/upload-status')

                if (data) {
                    let parsedData = data['next-step-details']
                        .filter(item => item['category'] === 'DOFIC')
                        .flatMap(item => item['upload-types'])
                        .map(uploadType => ({
                            ...uploadType,
                            required: data['next-step-details']
                                .find(item => item['category'] === 'DOFIC')['mandatory']
                        }));

                    if (parsedData.length === 0) {
                        const OLDDO = data['next-step-details']
                            .filter(item => item['category'] === 'OLDDO')
                            .flatMap(item => item['upload-types'])
                            .map(uploadType => ({
                                ...uploadType,
                                required: data['next-step-details']
                                    .find(item => item['category'] === 'OLDDO')['mandatory']
                            }));

                        if (OLDDO) {
                            console.log('OLDDO', OLDDO)
                            parsedData = OLDDO;
                        }
                    }

                    const others = data['next-step-details']
                        .filter(item => item['category'] === 'DCOMP')
                        .map(item => item['upload-types'])
                        .flat()
                        .find(item => item['code'] === 'OTHER');

                    if (others) {
                        parsedData.push(others);
                    }


                    console.log('parsedData', parsedData)
                    commit('SET_UPLOAD_STATUS', parsedData)
                }
            }

            getData()
        } catch (error) {
            loading(dispatch, false)
        }
    },

    async uploadDocument({dispatch, commit}, document) {
        try {
            const getData = async () => {
                const formData = new FormData()
                formData.append('filedata', document.filedata)
                formData.append('image-id', document.imageId)
                formData.append('license-driver', document.licenseDriver)

                await axios.post('/api/upload', formData)

                /* forcando upload-status */
                const data = await axios.get('/api/upload-status')

                if (data) {
                    let parsedData = data['next-step-details']
                        .filter(item => item['category'] === 'DOFIC')
                        .flatMap(item => item['upload-types'])
                        .map(uploadType => ({
                            ...uploadType,
                            required: data['next-step-details']
                                .find(item => item['category'] === 'DOFIC')['mandatory']
                        }));

                    if (parsedData.length === 0) {
                        const OLDDO = data['next-step-details']
                            .filter(item => item['category'] === 'OLDDO')
                            .flatMap(item => item['upload-types'])
                            .map(uploadType => ({
                                ...uploadType,
                                required: data['next-step-details']
                                    .find(item => item['category'] === 'OLDDO')['mandatory']
                            }));

                        if (OLDDO) {
                            console.log('OLDDO', OLDDO)
                            parsedData = OLDDO;
                        }
                    }

                    const others = data['next-step-details']
                        .filter(item => item['category'] === 'DCOMP')
                        .map(item => item['upload-types'])
                        .flat()
                        .find(item => item['code'] === 'OTHER');

                    if (others) {
                        parsedData.push(others);
                    }
                    console.log('parsedData', parsedData)
                    commit('SET_UPLOAD_STATUS', parsedData)
                }
            }

            getData()
        } catch (error) {
            loading(dispatch, false)
        } finally {
            dispatch('upload/retrieveUploadStatus')
        }
    },

    async finishProcess({dispatch}) {
        try {
            const getData = async () => {
                const formData = new FormData()
                await axios.post('/api/finish-upload', formData)
            }

            getData()
        } catch (error) {
            loading(dispatch, false)
        }
    },
}

const mutations = {
    SET_UPLOAD_STATUS(state, uploadStatus) {
        state.uploadStatus = uploadStatus
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
