import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const defaultChildren = [
  {
    path: '/index.html',
    component: () => import('./views/Login.vue'),
  },
  {
    path: '/',
    name: 'login',
    component: () => import('./views/Login.vue'),
  },
  {
    path: 'validate-sms',
    name: 'validateSms',
    component: () => import('./views/ValidateSms'),
  },
  {
    path: 'proposal',
    name: 'proposalChoice',
    component: () => import('./views/ProposalChoice.vue'),
  },
  {
    path: 'selfie',
    name: 'selfie',
    // component: () => import('./views/Selfie.vue'),
    component: () => import('./views/SelfieSaffe.vue'),
  },
  {
    path: 'selfie2',
    name: 'selfie2',
    // component: () => import('./views/Selfie.vue'),
    component: () => import('./views/SelfieUnico.vue'),
  },
  {
    path: 'instructions',
    name: 'instructions',
    component: () => import('./views/Instructions.vue'),
  },
  {
    path: 'sign-documents',
    name: 'signDocuments',
    component: () => import('./views/SignDocuments.vue'),
  },
  {
    path: 'upload-documents',
    name: 'uploadDocuments',
    component: () => import('./views/UploadDocuments.vue'),
  },
  {
    path: 'finished',
    name: 'finished',
    component: () => import('./views/Finished.vue'),
  },
  {
    path: 'old-selfie',
    name: 'selfiepoc',
    component: () => import('./views/SelfiePoc.vue'),
  },
]

const routes = [
  {
    path: '/version',
    name: 'Version',
    component: () => import('./views/Version.vue'),
  },
  {
    path: '/',
    name: 'wrapper',
    component: () => import('./views/Wrapper.vue'),
    children: defaultChildren,
  },
  {
    path: '/:partner',
    name: 'wrapper',
    component: () => import('./views/Wrapper.vue'),
    children: defaultChildren,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
