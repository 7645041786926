<template>
  <div id="app">
    <div v-if="showOverlay" class="overlay"></div>
    <notifications group="foo" classes="alerts-brc" />

    <div id="alerts" />

    <div
      class="loading-holder"
      :class="{ visible: loading }"
      onclick="return false;"
    >
      <span class="message" v-if="message && message !== ''">{{
          message
        }}</span>
      <div class="loading"></div>
    </div>

    <div v-if="!validNavigator">
      <div class="unsupported-browser">
        <div v-if="getOS() === 'IOS'">
          Navegador não suportado, por favor entre novamente utilizando o
          Safari.
        </div>
        <div v-else>
          Navegador não suportado, por favor entre novamente utilizando uma
          versão atualizada do Chrome, Firefox, Opera ou Edge.
        </div>
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<style lang="scss">
#app {
  color: $grey-650;

  .loading-holder {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    transition: all 0.25s;
    z-index: 99999999;
    opacity: 0;
    visibility: hidden;
    //top: -50px;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .message {
      position: fixed;
      left: 50%;
      top: calc(60%);
      transform: translateX(-50%);
      color: var(--first-color);
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .loading {
      position: fixed;
      border: 3px solid transparent;
      border-left: 3px solid var(--first-color);
      border-top: 3px solid var(--first-color);
      border-radius: 50%;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      animation: loading 1s linear infinite;
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.unsupported-browser {
  background: $black-40-transparent;
  font-size: x-large;
  margin: 250px 50px 45px 50px;
  padding: 25px 45px 25px 45px;
  border: 3px solid;
}
</style>

<script>
import { mapGetters } from 'vuex'
import getBrowserInfo from './util/getBrowserInfo'
// import getPlattaform from "./util/getPlattaform";
import constants from '@/util/constants'

export default {
  name: 'App',

  data() {
    return {
      showOverlay: false,
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading/loading',
      error: 'message/error',
      success: 'message/success',
      alert: 'message/alert',
      message: 'loading/message',
      validNavigator: 'validNavigator/validNavigator',
    }),
    notifications() {
      return this.$notify.state.notifications
    },
    firstColor() {
      console.log(this.partner, constants[this.$store.state.partner.partner || 'default']['--first-color'])
      if (this.constants[this.partner || 'default']) {
        return constants[this.partner || 'default']['--first-color']
      }

      return '#000'
    },
  },

  watch: {
    notifications: {
      handler(newVal) {
        this.showOverlay = newVal?.length > 0;
      },
      deep: true,
      immediate: true
    },
    error(alert) {
      if (alert.id) {
        this.showOverlay = true;
        this.$notify({
          group: 'foo',
          type: 'error',
          text: alert.message || 'Um erro ocorreu!',
          duration: 4000,
        })

        setTimeout(() => this.showOverlay = false, 5000)
      }
    },
    success(alert) {
      if (alert.id) {
        this.showOverlay = true;
        this.$notify({
          group: 'foo',
          type: 'sucess',
          text: alert.message,
          duration: 4000,
        })
        setTimeout(() => this.showOverlay = false, 5000)
      }
    },
    alert(alert) {
      if (alert.id) {
        this.showOverlay = true;
        this.$notify({
          group: 'foo',
          type: 'alert',
          text: alert.message,
          duration: 5000,
        })
        setTimeout(() => this.showOverlay = false, 6000)
      }
    },
  },

  methods: {
    getOS() {
      const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'MAC_OS'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'IOS'
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'WINDOWS'
      } else if (/Android/.test(userAgent)) {
        os = 'ANDROID'
      } else if (!os && /Linux/.test(platform)) {
        os = 'LINUX'
      }

      return os
    },

    setFirstColor() {
      setTimeout(() => {
        let item = localStorage.getItem('partner')
        let partner = this.$store.state.partner.partner || 'default'
        if (item && item !== 'undefined') {
          partner = item
        }
        localStorage.setItem('partner', partner)
        console.log(partner, constants[partner]['--first-color'] || '#000')
        const color = constants[partner]['--first-color'] || '#000'
        document.documentElement.style.setProperty('--first-color', color)

      }, 1000)
    },

    getPlattaform() {
      const userAgent = window.navigator.userAgent
      const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
      const isFirefox = typeof InstallTrigger !== 'undefined'
      const isSafari = userAgent.indexOf('CriOS') < 0 // if it is CriOS it is Chrome
      const isIE = /*@cc_on!@*/ false || !!document.documentMode
      const isEdge = !isIE && !!window.StyleMedia
      const isChrome = userAgent.indexOf('CriOS') > -1
      const isEdgeChromium =
        isChrome && navigator.userAgent.indexOf('Edg') != -1
      const isBlink = (isChrome || isOpera) && !!window.CSS

      return {
        isOpera,
        isFirefox,
        isSafari,
        isIE,
        isEdge,
        isChrome,
        isEdgeChromium,
        isBlink,
      }
    },

    handleNavigatorDetails() {
      const os = this.getOS()
      const {
        isSafari,
        isOpera,
        isFirefox,
        isChrome,
        isEdge,
      } = this.getPlattaform()

      const browserInfo = getBrowserInfo()

      console.log(browserInfo)

      console.log(`OS is ${os}`)
      console.log('if ' + (isOpera || isFirefox || isChrome || isEdge))

      if (os === 'IOS' && !isSafari) {
        this.$store.dispatch('validNavigator/setValidNavigator', false)
        // } else if (os !== "IOS" && (isOpera || isFirefox || isChrome || isEdge)) {
        // this.$store.dispatch("validNavigator/setValidNavigator", true);
      } else {
        this.$store.dispatch('validNavigator/setValidNavigator', true)
      }

      // this.$store.dispatch("validNavigator/setValidNavigator", true);
    },
    handleGeolocalization() {
      const noAcceptToken = localStorage.getItem('NO_ACCEPT_TOKEN')
      const noAcceptTokenExpiration = localStorage.getItem(
        'NO_ACCEPT_TOKEN_EXPIRATION',
      )

      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          const currentTime = new Date().getTime()

          if (noAcceptToken && noAcceptTokenExpiration < currentTime) {
            localStorage.removeItem('NO_ACCEPT_TOKEN')
            localStorage.removeItem('NO_ACCEPT_TOKEN_EXPIRATION')
          }

          if (noAcceptToken) {
            reject({
              code: 1,
              message: `User rejected and it will expire in ${noAcceptTokenExpiration}`,
            })
          }

          navigator.geolocation.getCurrentPosition(
            position => {
              resolve(position.coords)
            },
            function(error) {
              reject(error)
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
            },
          )
        } else {
          reject({
            code: 990011,
            message: 'Geolocation is not supported by this browser.',
          })
        }
      })
    },
  },

  async mounted() {
    const ASK_AGAIN_GEOLOCATION = 7

    this.setFirstColor()

    try {
      const respHandleOS = await this.handleNavigatorDetails()
      console.log(`call handleNavigator ${respHandleOS}`)
      const geolocation = await this.handleGeolocalization()

      if (geolocation) {
        this.$store.dispatch('geolocation/setGeolocation', geolocation)

        localStorage.setItem('ACCEPT_TOKEN', true)
      }
    } catch (err) {
      if (err.code === 1) {
        if (!localStorage.getItem('NO_ACCEPT_TOKEN')) {
          this.$store.dispatch(
            'message/sendAlert',
            'Não disponibilizar a geolocalização ativada! Será solicitada novamente em sete dias!',
          )

          const noAcceptTokenExpiraton = new Date()
          noAcceptTokenExpiraton.setDate(
            noAcceptTokenExpiraton.getDate() + ASK_AGAIN_GEOLOCATION,
          )

          localStorage.setItem('NO_ACCEPT_TOKEN', true)
          localStorage.setItem(
            'NO_ACCEPT_TOKEN_EXPIRATION',
            noAcceptTokenExpiraton.getTime(),
          )
        }
      } else if (err.code === 990011) {
        // store.dispatch("geolocationModal/toggleShowModal", true);
      } else {
        console.log(err)
      }
    }

    this.setFirstColor()
  },
}
</script>
