const state = {
  validNavigator: true,
}

const getters = {
  validNavigator: (state) => state.validNavigator,
}

const actions = {
  setValidNavigator({ commit }, validNavigator) {
    commit('SET_VALID_NAVIGATOR', validNavigator)
  },
}

const mutations = {
  SET_VALID_NAVIGATOR(state, validNavigator) {
    state.validNavigator = validNavigator
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
