<template>
  <div class="modal-overlay" v-if="opened">
    <div class="modal-container">
      <div class="modal__header">
        <div class="modal__header__title">Aviso</div>
      </div>
      <div class="modal__slot">
        <slot></slot>
      </div>
      <button class="btn-next-modal spaced" @click="close">
        Fechar
      </button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import constants from "@/util/constants";

export default {
  name: 'Modal',
  components: {},
  props: {
    opened: Boolean,
    doit: Function
  },
  computed: {
    ...mapGetters({
      partner: 'partner/partner',
    }),

    firstColor() {
      if (this.constants[this.partner || 'default']) {
        return constants[this.partner || 'default']['--first-color']
      }

      return '#000'
    },
  },

  methods: {
    validateSms() {
      this.$store.dispatch('auth/validateSms', this.code)
    },

    reSend() {
      console.log('resend')
      this.$store.dispatch('auth/reSend')
    },

    close() {
      console.log(this.doit)
      this.doit.call(null, null);
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, .8);
}

.modal-container {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 200px;
  width: 350px;
  border-radius: 10px;

  .modal__header {
    display: flex;
    padding-top: 16px;
    padding-bottom: 4px;
    justify-content: center;
    font-weight: bold;

    .modal__header__close-icon {
      padding: 17px;
      border-radius: 50%;
      box-shadow: 0 3px 6px $black-26-transparent;
    }
  }

  .modal__slot {
    margin: 10px;
    max-width: 100%;
    white-space: pre-wrap;
  }


  .btn-next-modal {
    background: rgb(204, 9, 47);
    border-radius: 24px;
    padding: 20px 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    margin: 10px;
    display: flex;

    @media (max-width: 768px) {
      width: 256px;
      margin: 10px auto;
    }

    &.outlined {
      background: transparent;
      border: 1px solid var(--first-color);
      color: var(--first-color);
    }

    &:disabled {
      opacity: 0.3;
    }

    &.spaced {
      margin-top: 20px;
      justify-content: center;
    }

  }


}
</style>
