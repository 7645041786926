import { loading, FLOW_PAGES_ENUM } from '../common'
import axios from '../axios-interceptor'
import store from '../'

const state = {
  token: '',
  documentNumber: '',
  phone: '',
}

const getters = {
  token: state => state.token,
  documentNumber: state => state.documentNumber,
  phone: state => state.phone,
}

const actions = {
  async login({ dispatch, commit }, data) {
    try {
      const partner = store.getters['partner/partner'] || ''
      const formDataLogin = new FormData()

      formDataLogin.append('document', data.documentNumber)
      formDataLogin.append('cellphone', data.phone)

      commit('SET_DOCUMENT_NUMBER', data.documentNumber)
      commit('SET_PHONE', data.phone)

      await axios.post('/api/login', formDataLogin, {
        headers: { parceira: partner },
      })

      dispatch('flux/nextStep', FLOW_PAGES_ENUM.LOGIN, { root: true })
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },

  async validateSms({ dispatch }, code) {
    try {
      const clearedCode = code.split(' ').join('')
      if (clearedCode === '') {
        return dispatch(
          'message/sendAlert',
          'Por favor, verifique o código digitado.',
          {
            root: true,
          },
        )
      }

      const formData = new FormData()

      formData.append('sms-token', clearedCode)

      await axios.post('/api/token', formData)
      store.dispatch('proposal/getProposalsList')
      // dispatch('flux/nextStep', FLOW_PAGES_ENUM.VALIDATE_SMS, { root: true })
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },

  async reSend({ dispatch }) {
    try {
      await axios.post('/api/resend-token')
      dispatch('message/sendSuccess', 'SMS reenviado com sucesso!', {
        root: true,
      })
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },

  async updateToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
}

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_DOCUMENT_NUMBER(state, documentNumber) {
    state.documentNumber = documentNumber
  },
  SET_PHONE(state, phone) {
    state.phone = phone
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
